.topbar-container {
  height: 80px;
  background-color: #000;
  padding: 0 20px 0 20px;
}

.p-submenu-list {
  .p-menuitem-text {
    color: #000 !important;
    font-size: normal;
    font-family: "Inter";
  }
  a:hover .p-menuitem-text {
    color: #fff !important;
  }

  .p-menuitem-link:not(.p-disabled):hover {
    background: rgba(54, 213, 255, 1) !important;
    color: #fff !important;
    border-radius: 12px 12px 0px 0px !important;
  }
}

.p-menubar-root-list {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  justify-content: end;
  align-items: stretch;
  align-content: center;
}
.lg-hidden {
  display: hidden;
}

//
.p-sidebar-mask {
  z-index: 9999 !important;
}
.p-mobile-view-btn {
  background: transparent !important;
}

//
.p-menubar {
  padding: 0.5rem 30px;
  background: #000;
  color: #1c2938;
  border: none;
  border-radius: 0;
}
.p-menubar .p-menuitem-link .p-menuitem-text {
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 1px;
  font-family: "kagitingan";
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgba(255, 102, 205, 1);
}
.p-menubar .p-submenu-list {
  padding: 0.5rem 0;
  background: #ffffff;
  border: 0 none;
  border-radius: 12px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  width: 12.5rem;
}
.p-menubar .p-menuitem-link .p-menuitem-text:active {
  color: var(--pink);
}
.p-menubar
  .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
  > .p-menuitem-content {
  color: #4b5563;
  background: none;
}

.p-menubar .p-menuitem.p-highlight > .p-menuitem-content {
  color: #0e7490;
  background: none;
}
.p-ink {
  display: block;
  position: absolute;
  background: none;
  border-radius: 100%;
  transform: scale(0);
}
.p-menubar
  .p-menuitem:not(.p-highlight):not(.p-disabled)
  > .p-menuitem-content:hover {
  background: none !important;
}
.p-menuitem .p-menuitem-active .p-highlight {
  background-color: none;
}
.p-submenu-list {
  .p-menuitem-text {
    color: #fff;
    font-weight: 400;
    font-size: 14px !important;
    font-family: inter !important;
  }
}

@media screen and (max-width: 960px) {
  .topbar-container {
    height: 80px;
    background-color: #000;
    padding: 0px;
  }
  .lg-hidden {
    display: hidden;
  }
  .p-menuitem-text {
    color: #000 !important;
    font-size: medium;
  }
  .p-submenu-list {
    .p-menuitem-link:not(.p-disabled):hover {
      background: rgba(54, 213, 255, 1) !important;
      color: #fff !important;
      border-radius: 12px 12px 0px 0px !important;
    }
  }
  .p-menubar {
    background-color: transparent !important;
    border: 0;
  }
  .mobile-responsive {
    position: absolute;
  }
}
