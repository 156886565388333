.bg-ligthgray {
  background-color: $lightgray;
}

.bg-primary-blue {
  background-color: #36d5ff;
}

.bg-pink {
  background-color: $pink !important;
}
