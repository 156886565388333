// Buttons
.p-button-label {
  font-weight: 600;
  letter-spacing: 1px;
  font-family: "kagitingan";
}

.p-checkbox.p-highlight .p-checkbox-box {
  border-color: var(--lightblue);
  background: var(--lightblue);
}
.p-checkbox .p-checkbox-box {
  border: 2px solid lightblue;

  color: var(--lightblue);
}
.p-button.p-button-info,
.p-button-group.p-button-info > .p-button,
.p-splitbutton.p-button-info > .p-button,
.p-fileupload-choose.p-button-info {
  color: #ffffff;
  background: var(--lightblue);
  border: 1px solid var(--lightblue);
}
