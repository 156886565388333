.custom-calendar-input > .p-inputtext {
  font-family: inter;
  font-size: 0.875rem;
  color: #1c2938;
  background: #fff;
  padding: 0.75rem 0.75rem;
  border: 1px solid #e8e8e8;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  appearance: none;
  border-radius: 12px;
  position: relative;
}
.react-tel-input .form-control {
  border: 1px solid #e8e8e8 !important;
}

.custom-button-calendar {
  .p-inputtext {
    border: none !important;
  }
  .custom-calendar-input .p-button.p-button-icon-only {
    position: absolute;
    width: 4rem;
    height: 38px;
    padding: 0.75rem 0;
    color: #868383;
    border-radius: 12px;
    border: 1px solid #e0e0e0 !important;
  }
}

.custom-calendar-input {
  .p-button {
    position: absolute;
    color: #fff;
    background: #fff;
    height: 35px;
    margin: 4px;
    border: 1px solid #fff !important;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
    border-radius: 0;
    right: 0;
  }
  .p-button.p-button-icon-only {
    width: 2rem;
    padding: 0.75rem 0;
    color: #000;
  }
  .p-button:enabled:active,
  .p-button:not(button):not(a):not(.p-disabled):active {
    background: #ffffff;
    color: #000000;
    border-color: #ebebeb;
  }
  .p-button:focus {
    box-shadow: none;
  }
}
.p-calendar:not(.p-calendar-disabled).p-focus > .p-inputtext {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: none;
}

.p-button:enabled:hover,
.p-button:not(button):not(a):not(.p-disabled):hover {
  background: #fff;
  color: var(--ligthblue);
  border-color: var(--ligthblue);
}
.p-password .p-password-show-icon,
.p-password .p-password-hide-icon {
  right: 26px !important;
}
.react-tel-input + .phone {
  padding-left: 30px;
}

.react-tel-input:has(input:not(:placeholder-shown)) + label {
  top: 0px;
  padding-left: 0px;
  font-size: 14px;
  font-weight: 500;
  color: #000;
}
.p-float-label:has(input:focus) label,
.p-float-label:has(input.p-filled) label,
.p-float-label:has(input:-webkit-autofill) label,
.p-float-label:has(textarea:focus) label,
.p-float-label:has(textarea.p-filled) label,
.p-float-label:has(.p-inputwrapper-focus) label,
.p-float-label:has(.p-inputwrapper-filled)
  label
  .react-tel-input:has(input:focus)
  + label,
.p-float-label:has(.p-inputwrapper-filled) label {
  top: 0px;
  padding-left: 0px;
  font-size: 14px;
  font-weight: 500;
  color: #000;
}
.react-tel-input ~ .phone {
  z-index: 9999;
}

.react-tel-input .form-control {
  border-radius: 12px !important;
  height: 42px !important;
}
.react-tel-input .flag-dropdown {
  border-radius: 12px 0 0 12px !important;
  background-color: #ffffff !important;
  border: 1px solid #e8e8e8 !important;
  border-right: none !important;
}
.react-tel-input .flag-dropdown .selected-flag:hover {
  border-radius: 12px 0 0 12px !important;
  background-color: #ffffff !important;
  border: none;
  border-right: none !important;
}
.selected-flag:focus {
  background-color: #fff;
  border-radius: 12px 0 0 12px !important;
}
.react-tel-input .selected-flag {
  outline: none;
  position: relative;
  width: 38px;
  height: 100%;
  padding: 0 0 0 8px;
  border-radius: 12px 0 0 12px !important;
  padding: 5px;
}

// DropDown

.p-dropdown {
  background: #fff;
  border: 1px solid #e8e8e8;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 0;
}
.p-dropdown-panel {
  z-index: 9999 !important;
  border-radius: 12px;
}

// inputs

.p-button.p-button-info:enabled:active,
.p-button.p-button-info:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-info > .p-button:enabled:active,
.p-buttonset.p-button-info
  > .p-button:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-info > .p-button:enabled:active,
.p-splitbutton.p-button-info
  > .p-button:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-info:enabled:active,
.p-fileupload-choose.p-button-info:not(button):not(a):not(.p-disabled):active {
  background: var(--lightblue);
  color: #fff;
  border-color: var(--lightblue);
}
.p-inputtext:enabled:hover {
  border-color: none !important;
}
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: none !important;
}

.button-calendar {
  .p-datepicker {
    .p-button {
      position: relative !important;
    }
  }
}

// .p-multiselect:not(.p-disabled):hover {
//   border-color: transparent;
// }
