@font-face {
  font-family: "kagitingan";
  src: url("../fonts/Kagitingan-Bold.otf") format("opentype");
}

@import "./_variables.scss";
@import "./_bg-colors.scss";
@import "./_text-colors.scss";

//theme
@import "./theme.scss";

//pages

@import "./pages/login.scss";
@import "./pages/_footer.scss";

// Layout file

@import "./layout/Topbar.scss";
@import "./layout/ResgisterPage";

// component

@import "./components/_loader.scss";
@import "./components/_buttons.scss";
@import "./components/content-card.scss";
@import "./components/_input.scss";
@import "./components/_eventcard.scss";

///////////////////////////////////////////////////////////
////// All other style files will be imported below///////
/////////////////////////////////////////////////////////

// Data table
.p-datatable .p-datatable-thead > tr > th {
  font-weight: 500 !important;
  font-size: 14px;
}
.p-datatable .p-sortable-column.p-highlight {
  background: #eef2ff;
  color: #535353 !important;
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #535353 !important;
}

.p-datatable-resizable-table > .p-datatable-thead > tr > th,
.p-datatable-resizable-table > .p-datatable-tfoot > tr > td,
.p-datatable-resizable-table > .p-datatable-tbody > tr > td {
  font-family: "Inter";
}
.p-datatable .p-datatable-thead > tr > th {
  background-color: rgba(244, 244, 244, 1);
  color: rgba(128, 128, 128, 1);
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: rgba(128, 128, 128, 1);
  margin-left: 0.5rem;
}

.p-component-overlay {
  background-color: rgba(15, 15, 15, 0.8) !important;
  transition-duration: 0.2s;
}
.hover-black:hover {
  background: #000000 !important;
}

.scanner {
  position: relative;
  .scanner-close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 9999;
  }
}

.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Transparent background */
  z-index: 999; /* Make sure it appears on top */
}

input.timer-input:focus {
  outline: none;
  box-shadow: none;
}

.credit-card {
  width: 324px; /* Width of a credit card at 96 DPI */
  height: 204px; /* Height of a credit card at 96 DPI */
  display: flex;
}
