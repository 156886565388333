.pink {
  color: var(--pink);
}
.subheading {
  color: var(--darkgray);
}
.skyblue {
  color: var(--lightblue) !important;
}
.borderskyblue {
  border: 2px solid #36d5ff !important;
}
.text-blue {
  color: #36d5ff !important;
}
