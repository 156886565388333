.footerbg-img {
  background-image: url("../../images/footerImg.png");
  background-size: cover;
  border-radius: 12px;
  padding: 50px !important;
  margin-top: -160px !important;
}
@media screen and (max-width: 480px) {
  .footerbg-img {
    padding: 20px !important;
    margin-top: -136px !important;
  }
}
