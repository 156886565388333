@import "./assets/styles/all.scss";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
body {
  margin: 0;
  padding: 0;
}
* {
  font-family: Inter;
  scrollbar-width: thin; /* "auto" or "thin" */
  // scrollbar-color: black rgb(231, 231, 231); /* scroll thumb and track */
}
.h-100-top {
  min-height: calc(100vh - 80px);
  overflow: auto;
}
.text-6xl {
  font-size: 46px;
}
.text-900 {
  color: rgba(47, 47, 47, 1);
}
.inter {
  font-family: Inter;
}
.kagitingan {
  font-family: "kagitingan";
}
.h4 {
  color: rgba(30, 30, 30, 1);
}
.bg-black {
  background-color: #000;
}
.content-box {
  max-width: 1440px;
  margin: auto;
}
.center {
  display: flex;
  justify-content: center;
}
.p-inputtext {
  font-family: "Inter";
}
.activeLink .p-menuitem-link .p-menuitem-text {
  color: var(--pink);
}
