// Please write exact color name
:root {
  --midblue: #66c2cb;
  --lightgray: #f5f5f5;
  --lightblue: #36d5ff;
  --pink: rgba(255, 102, 205, 1);
  --gray: #d0d0d0;
  --darkgray: #808080;
}

/////////////////////////////////////////////////////////////////
///////////// Follow the same color name below//////////////////
///////////////////////////////////////////////////////////////

// These variables with $ sign will be used in all other style file
// No color code will be write in any style file directly

$gray: var(--gray);
$lightgray: var(--lightgray);
$lightblue: var(--lightblue);
$pink: var(--pink);
$darkgray: var(--darkgray);
$midblue: var(--midblue);
