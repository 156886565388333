.background-image {
  background-image: url("../../images/bg-login.jpeg");
  min-height: calc(100vh - 100px);
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.select-register-type {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  max-width: 210px;
  align-self: stretch;
  min-width: 210px;
  border-radius: 12px;
  background-color: #fff;
  border: 2px solid #e3e3e3;
}
@media screen and (max-width: 1280px) {

  .select-register-type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    max-width: 180px;
    align-self: stretch;
    min-width: 180px;
    border-radius: 12px;
    background-color: #fff;
    border: 2px solid #e3e3e3;
  }
  .icon-width svg {
    width: 60px;
    height: 60px;
  }
}
@media screen and (min-width: 1280px) {
  .min-width-lg {
    max-width: 800px;
  }
}
